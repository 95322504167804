import { defineRule } from 'vee-validate';
import { isValidCNPJ, isValidCPF } from '@transfeeradev/api-services/PersonTypeService';
import {
  isValidCreditcard,
  isValidExpirationDate
} from '@transfeeradev/api-services/CreditcardService';
import {
  isValidEmail,
  isValidPhone,
  hasAtLeastTwoWords,
  removeSpecialCharacters
} from '@/validations';
import { min, digits } from '@vee-validate/rules';

export const defineRules = () => {
  defineRule('tax_id', value => {
    if (!isValidCPF(value) && !isValidCNPJ(value)) {
      return 'CPF ou CNPJ inválido';
    }

    return true;
  });

  defineRule('required', (value, opts, ctx) => {
    if (!value) {
      return ctx.label ? ctx.label + ' é obrigatório' : 'Campo obrigatório';
    }

    return true;
  });

  defineRule('email', value => {
    if (!isValidEmail(value)) {
      return 'E-mail inválido';
    }

    return true;
  });

  defineRule('phone', value => {
    if (!isValidPhone(value)) {
      return 'Telefone inválido';
    }

    return true;
  });

  defineRule('min_two_words', (value, _, ctx) => {
    if (!hasAtLeastTwoWords(value)) {
      return `${ctx.label} deve ter no mínimo 2 palavras`;
    }

    return true;
  });

  defineRule('min', (value, options, ctx) => {
    const unmaskedValue = removeSpecialCharacters(value);
    const [minCharacters] = options;
    if (!min(unmaskedValue, { length: minCharacters })) {
      return `${ctx.label} deve ter ${minCharacters} dígitos`;
    }

    return true;
  });

  defineRule('digits', (value, options, ctx) => {
    const unmaskedValue = removeSpecialCharacters(value);
    const [minCharacters] = options;
    if (!digits(unmaskedValue, { length: minCharacters })) {
      return `${ctx.label} deve ter ${minCharacters} números`;
    }

    return true;
  });

  defineRule('credit_card', value => {
    if (!isValidCreditcard(value)) {
      return 'Cartão de crédito inválido';
    }

    return true;
  });

  defineRule('card_expiration_date', value => {
    const [month, year] = value.split('/');

    if (!isValidExpirationDate(month, year)) {
      return 'Data de expiração inválida';
    }

    return true;
  });
};
