import { createRouter, createWebHistory } from 'vue-router';

const routeDef = {
  PaymentLinkExpiredPage: {
    name: 'PaymentLinkExpiredPage',
    component: () => import('@/pages/PaymentLinkExpiredPage.vue')
  },
  Checkout: {
    name: 'Checkout',
    component: () => import('@/pages/PaymentLinkGateway.vue')
  },
  CheckoutPix: {
    name: 'CheckoutPix',
    component: () => import('@/pages/pix-checkout/PixCheckout.vue')
  },
  CheckoutCreditCardSuccess: {
    name: 'CheckoutCreditCardSuccess',
    component: () => import('@/pages/card-checkout/CardPaymentApprovedPage.vue')
  },
  CheckoutCreditCardNotAuthorized: {
    name: 'CheckoutCreditCardNotAuthorized',
    component: () => import('@/pages/card-checkout/CardPaymentRejectedPage.vue')
  }
};

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:id',
      component: () => import('@/PageLayout.vue'),
      children: [
        {
          path: '',
          component: routeDef.Checkout.component,
          name: routeDef.Checkout.name
        }
      ]
    },
    {
      path: '/:id',
      component: routeDef.PaymentLinkExpiredPage.component,
      name: routeDef.PaymentLinkExpiredPage.name
    },
    {
      path: '/:id',
      component: () => import('@/PageLayout.vue'),
      children: [
        {
          path: '',
          component: routeDef.CheckoutCreditCardSuccess.component,
          name: routeDef.CheckoutCreditCardSuccess.name
        }
      ]
    },
    {
      path: '/:id',
      component: () => import('@/PageLayout.vue'),
      children: [
        {
          path: '',
          component: routeDef.CheckoutCreditCardNotAuthorized.component,
          name: routeDef.CheckoutCreditCardNotAuthorized.name
        }
      ]
    }
  ]
});

export const routes = {
  paymentLinkExpiredRoute: id => ({
    name: routeDef.PaymentLinkExpiredPage.name,
    params: { id }
  }),
  checkoutRoute: id => ({
    name: routeDef.Checkout.name,
    params: { id }
  }),
  checkoutPixRoute: id => ({
    name: routeDef.CheckoutPix.name,
    params: { id }
  }),
  cardRoute: id => ({
    name: routeDef.CheckoutCreditCardSuccess.name,
    params: { id }
  })
};
