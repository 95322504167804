import * as Sentry from '@sentry/vue';

export const sentry = app => {
  const dsn = import.meta.env.VITE_APP_SENTRY_DSN;
  const isProd = import.meta.env.NODE_ENV === 'production';

  if (dsn) {
    Sentry.init({
      app,
      dsn,
      logErrors: !isProd,
      debug: !isProd,
      environment: import.meta.env.NODE_ENV,
      release: import.meta.env.VITE_APP_SENTRY_RELEASE,
      trackComponents: true
    });

    /**
     * TODO: remove this when we will have a better way to handle the new relic tracing with sentry.io
     * https://www.notion.so/transfeera/ADR-001-APM-a704f0d0d2e64f6c97b0b62cd69125c1
     * **/
    if (
      typeof window.newrelic !== 'undefined' &&
      typeof window.newrelic.setErrorHandler === 'function'
    ) {
      window.newrelic.setErrorHandler(function (error) {
        Sentry.captureException(error, { extra: { newrelic: 'yes' } });
      });
    }
  }
};
